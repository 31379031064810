.square {
    width: 60px;
    height: 60px;
    background-color: rgba(39, 219, 247, 0.647);
    opacity: 0.7;
    position: absolute;
    top: -60px;
    left: -60px;
    z-index: -1;
    animation: square 25s linear alternate infinite;
  
  }
  
  @keyframes square {
    to {
      transform: translate(100vw, 100vh);
    }
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ff97af;
    position: absolute;
    top: 200px;
    left: -100px;
    z-index: -1;
    animation: circle 25s linear alternate infinite;
  
  }
  
  @keyframes circle {
    to {
      transform: translate(100vw, -100vh);
    }
  }
  
  .rect {
    width: 50px;
    height: 100px;
    background-color: #da0c73ad;
    opacity: 0.5;
    position: absolute;
    top: 350px;
    left: -50px;
    z-index: -1;
    animation: rect 25s linear alternate infinite;
  
  }
  
  @keyframes rect {
    to {
      transform: translate(100vw, -50vh);
    }
  }