.totalcontainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
}

.totalcard {
  flex: 1;
  padding: 100px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}

.totalcircle {
  width: 100vh;
  height: 100vh;
  border-radius: 50%;
  opacity: 0.1;
  position: absolute;
  z-index: 999;
}


.totalcircle2 {
  background-color: rgba(255, 0, 0, 0.791);
  left: -50vh;
  top: -50vh;
}


.totaltitle {
  font-size: 50px;
  margin: 0;
}

.totalbrandName {
  color: #0e15d3e6;
}

.totaldesc {
  font-size: 20px;
  color: white;
  margin: 0;
}

.totalbutton {
  border: none;
  background-color: #00f64ada;
  color: white;
  font-weight: bold;
  padding: 6px;
  border-radius: 10px;
  width: 100px;
  cursor: pointer;
  animation: totalbutton 1s ease infinite alternate;
}


@keyframes totalbutton {
  to {
    background-color: #18f2b8aa;
  }
}

@media screen and (max-width: 480px) {
  .totalcontainer {
    flex-direction: column;
  }



  .totalcard {
    padding: 50px;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }

  .totaltitle {
    font-size: 30px;
  }

  .totaldesc {
    font-size: 14px;

  }
}