.vhwid {
	
	min-height: 100vh;

}


.name1 {
	margin: 10px 0;
}

.name2 {
	margin: 5px 0;
	text-transform: uppercase;
}

.name3 {
	font-size: 14px;
	line-height: 21px;
}

.card-container {
	/* #231E39 */
	background-color: #211846;
	border-radius: 5px;
	/* box-shadow: rgba(255, 255, 255, 0.958) 2px -3px 20px -3px; */

	box-shadow: rgba(255, 255, 255, 0.958) 1px 1px 20px 1px;

	color: white;

	position: relative;
	width: 420px;
	max-width: 100%;
	text-align: center;
	margin: 10px 0px;



}


.card-container .round {
	border: 1px solid #03BFCB;
	border-radius: 50%;
	object-fit: cover;
	width: 137px;
	height: 137px;
}



button.primary {

	background-color: #03BFCB;
	text-decoration: none;
	border: 1px solid #03BFCB;
	margin-right: 8px;
	border-radius: 3px;
	font-size: 14px;
	color: black;
	font-weight: 500;
	padding: 10px 16px;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);


}

button.primary:hover {
	text-decoration: none;
	background-color: #10d7e6;
	box-shadow: 0 2px 12px 0 rgba(217, 195, 195, 0.784), 0 17px 50px 0 rgba(0, 0, 0, 0.16);

}


.skills {
	background-color: #211846;
	text-align: left;
	padding: 15px;
	margin-top: 30px;
	border-radius: 5px;
}


.skills ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	font-size: 17px;
	font-weight: bold;
}

.skills ul li {
	border: 1px solid #2D2747;
	border-radius: 2px;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	margin: 0 7px 7px 0;
	padding: 7px;
}

.cddes {
	background-color: #231E39;
	color: white;
	margin: 10px;
	box-shadow: rgba(255, 255, 255, 31%) 2px 1px 20px -3px inset;

}
.i-title {
	
	height: 40px;
	overflow: hidden;
  }
  
  .i-title-wrapper {
	height: 100%;
	animation: move 10s ease-in-out infinite normal ;
	animation-delay: 2s;
  }
  
  @keyframes move {
	25% {
	  transform: translateY(-50px);
	}
	50% {
	  transform: translateY(-100px);
	}
	75% {
	  transform: translateY(-150px);
	}
	100% {
	  transform: translateY(-200px);
	}
  }
  
  .i-title-item {
	height: 50px;
	font-size: 30px;
	font-weight: bold;
	display: flex;
	align-items: center;
  }



@media(max-width:470px) {
	.card-container {
		width: 320px;

	}
}
