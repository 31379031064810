.servicetitle1 {
  padding: 20px;
  color: white;
  text-shadow: 1px 1px 25px #171452, 0 0 26px blue, 0 0 26px #15a2be;
}

.lote1 {
  height: 300px;
}

.bgcard2 {
  box-shadow: rgba(35, 30, 57, 0.861) 2px 1px 20px -3px inset;

}





.listskill1 ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: bold;

}

.listskill1 ul li {
  border: 1px solid #2D2747;
  border-radius: 2px;
  display: inline-block;
  color: white;
  font-size: 14px;
  font-weight: bold;
  margin: 0 7px 7px 0;
  padding: 7px;
  background-color: #171452;
}