.project {
    margin-top: 40px;
    border: 1px solid black;
    border-radius: 10px;
    animation: project-animation 10s linear infinite;
    object-fit: cover;
    margin: 30px;


}





.headtitle1 {

    padding: 20px;
    color: white;
    text-shadow: 1px 1px 25px #171452, 0 0 26px blue, 0 0 26px #15a2be;

}

.project img {

    border-radius: 10px;
    max-width: 100%;
    height: auto;

}

.project:hover>.project-des {
    opacity: 1;
    transform: scale(1.05);
    cursor: pointer;

}

.project:hover {
    animation-play-state: paused;
}




.project-des {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: 400;
    border-radius: 10px;
    background-color: #02052866;
    opacity: 0;
    transition: all 2;

}

@keyframes project-animation {
    0% {
        transform: rotate(-5deg);
    }

    25% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(5deg);
    }

    75% {
        transform: rotate(0deg);

    }

    100% {
        transform: rotate(-5deg);
    }

}

@media(max-width:485px) {
    .butdemo {
        font-size: 10px;
    }
}