.footer-bottom {
    padding: 15px 0;
    border-top: 1px solid #313646;
    background-color: rgb(3, 6, 42) !important;
    color: #b0b0b0;
    bottom: 0px;
    left: 0px;
    right: 0px;



}

@media(max-width:400px) {
    .footer-site-info {
        font-size: 12px;
    }

    .namefollow1 {
        font-size: 12px;
    }

}










@media screen and (max-width:480px) {}