.parallaximg1 {
  height: 100vh;
  width: 100vw;
  background-size: cover;
}

.firsttitle1 {
  text-shadow: 1px 1px 25px #171452, 0 0 26px blue, 0 0 26px #15a2be;
  margin-top: 3px;
  color: white;
  font-size: 26px;
  border-radius: 7px;
  background: rgb(23, 20, 82);
  background: linear-gradient(90deg, rgba(23, 20, 82, 1) 0%, rgba(27, 175, 204, 1) 100%);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.23);

}

.flex-with-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;


}

.parallax-content div h1 {
  font-weight: bold;
  font-size: 100px;
  color: rgba(255, 255, 255, 0.878);
}

.parallax-content div h1:hover {
  color: rgb(35, 194, 226);
  cursor: default;
  text-shadow: 0px 0px 10px rgba(100, 162, 221, 0.817);
}

/* Landingpage3.css */

.bgimg {
  background-image: url(/public/color2.jpg);
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
}


@media(max-width:750px) {
  .parallax-content div h1 {
    font-weight: bold;
    font-size: 90px;

  }
}