.menu-icon {
  color: #00BFA6;
  font-size: 55px;

  z-index: 3;
  cursor: pointer;
  margin: 1px solid black;
  background-color: #394C59;
  border-radius: 50%;


}


.n-box1 {
  background: #001829;
  box-shadow: 25px 25px 50px #000d17, -25px -25px 50px #00233b;


}

.show-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.78;
  height: 25vh;
  width: 100%;
  border-bottom-right-radius: 45%;
  transition: all 0.4s;
  z-index: 2;

}

.hide-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  transition: all 0s;
  z-index: 1;

}

.show-header li {
  color: white;
  list-style: none;

}

.show-header a {
  color: white;
  text-decoration: none;
}

.active {
  border: 3px solid cyan;
  padding: 5px;
  border-radius: 10px;

}

active:active {
  font-weight: bold;
  color: cyan;
}



@media(max-width:750px) {


  .menu-icon {
    color: white;
    font-size: 50px;

  }
}




@media(max-width:410px) {


  .menu-icon {
    color: white;
    font-size: 45px;

  }

  li {
    font-size: 14px;
  }

  .active {
    border: 3px solid cyan;
    padding: 5px;
    border-radius: 5px;

  }
}