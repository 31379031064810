.contactheight {
    min-height: 100vh;
    align-items: center;
}


.bgcontact2 {
    background-color: rgb(21, 140, 186);
    font-weight: bold;
    color: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.77) 2px -3px 24px -3px inset;
}

.contacthref1 {

    background-color: black;
    text-decoration: none !important;
    padding: 4px !important;
}